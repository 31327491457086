<template>
  <v-form data-vv-scope="enclosed-ticket">
    <v-layout class="mb-5">
      <v-flex xs12>
        <div class="mb-4 sw-h5">
          {{ $t("ticket_template_wizard.ticket_body") }}
        </div>
        <QuillEditor v-model="ticket.body" :config="editorConfig" />
        <ErrorMessages
          :error-messages="errors.collect('enclosed-ticket.body')"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs4 class="pr-5">
        <v-card flat class="border-radius sw-primary-bg">
          <v-card-text class="py-5 text-center">
            <TranslatableTooltip
              :content="
                $t('ticket_template_wizard.ticket_header_image_tooltip')
              "
              class="center"
            >
              <div class="mb-2 sw-h5">
                {{ $t("ticket_template_wizard.ticket_header_image") }}
              </div>
            </TranslatableTooltip>
            <MediaManager
              v-model="ticket.ticket_header_image"
              :media-type="'images'"
              label=""
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
export default {
  inject: ["parentValidator"],
  data: () => ({}),
  computed: {
    ticket: {
      get() {
        return this.$store.getters.ticketTemplateWizardTicket;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardTicket", val);
      },
    },
    editorConfig() {
      return {
        shortcodes: true,
        label: "",
        placeholder: this.$t("ticket_template_wizard.ticket_body_placeholder"),
        style: {
          height: "400px",
          "--primary-color": "#1f363d",
          "--secondary-color": "#3869D4",
        },
      };
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>
