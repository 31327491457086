<template>
  <v-form data-vv-scope="receivers">
    <v-layout row wrap>
      <v-flex xs12>
        <v-flex xs8 class="pr-5">
          <v-flex xs12 class="mb-5">
            <div class="sw-h5">
              {{ $t("ticket_template_wizard.add_audience") }}
            </div>
            <AudienceSelector v-model="email.audiences" />
          </v-flex>
          <v-flex xs12 class="mb-5">
            <div class="sw-h5">
              {{ $t("ticket_template_wizard.add_guests") }}
            </div>
            <RecipientSelector v-model="email.recipients" />
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout v-if="email && email.id">
      <v-flex xs8 class="pr-5">
        <v-layout row wrap>
          <v-flex xs12 class="mb-1 sw-h5">
            {{ $t("ticket_template_wizard.send_test") }}
          </v-flex>
          <v-flex xs12>
            <TestMessages
              :group-id="groupId"
              :message-template-id="email.id"
              :success-message="[
                'ticket_template_wizard.test_message_success',
                'lang',
              ]"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import AudienceSelector from "@/components/AudienceSelector.vue";
import RecipientSelector from "@/components/RecipientSelector.vue";
import TestMessages from "@/components/TestMessages.vue";

export default {
  inject: ["parentValidator"],
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    email: {
      get() {
        return this.$store.getters.ticketTemplateWizardEmail;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardEmail", val);
      },
    },
  },
  components: {
    AudienceSelector,
    RecipientSelector,
    TestMessages,
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped></style>
