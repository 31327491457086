<template>
  <v-form data-vv-scope="checkin-data">
    <v-layout row wrap>
      <v-flex xs12>
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.user_info_template_tooltip')"
        >
          <div class="mb-4 sw-h5">
            {{ $t("ticket_template_wizard.user_info_template") }}
          </div>
        </TranslatableTooltip>
        <QuillEditor
          v-model="ticket.user_info_template"
          :config="editorConfig"
        />
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    ticket: {
      get() {
        return this.$store.getters.ticketTemplateWizardTicket;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardTicket", val);
      },
    },
    editorConfig() {
      return {
        shortcodes: true,
        label: "",
        placeholder: this.$t("ticket_template_wizard.user_info_template"),
        style: {
          height: "400px",
        },
      };
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>
