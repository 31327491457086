<template>
  <v-form data-vv-scope="ticket">
    <v-layout row wrap>
      <v-flex xs6 class="mb-4">
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.ticket_name_label_tooltip')"
        >
          <div class="sw-h5">
            {{ $t("ticket_template_wizard.name") }}
          </div>
        </TranslatableTooltip>
        <v-text-field
          v-model="plugin.name"
          v-validate.disable="'required'"
          data-vv-name="name"
          :data-vv-as="$t('ticket_template_wizard.name')"
          :placeholder="$t('ticket_template_wizard.name_placeholder')"
          hide-details
        ></v-text-field>
        <ErrorMessages :error-messages="errors.collect('ticket.name')" />
      </v-flex>
      <v-flex xs12 class="mb-4">
        <div class="sw-h5">
          {{ $t("ticket_template_wizard.ticket_features_label") }}
        </div>
      </v-flex>
      <v-flex xs3 class="pr-4">
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.send_email_label_tooltip')"
        >
          <v-switch v-model="plugin.ticketwizard_email_enabled" class="my-0">
            <template slot="label">
              <div class="black--text">
                {{ $t("ticket_template_wizard.send_email_label") }}
              </div>
            </template>
          </v-switch>
        </TranslatableTooltip>
      </v-flex>
      <v-flex xs3 class="pr-4">
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.enable_menu_label_tooltip')"
        >
          <v-switch v-model="plugin.ticketwizard_menu_enabled" class="my-0">
            <template slot="label">
              <div class="black--text">
                {{ $t("ticket_template_wizard.enable_menu_label") }}
              </div>
            </template>
          </v-switch>
        </TranslatableTooltip>
      </v-flex>
      <v-flex xs4 class="pr-4">
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.enable_user_info_label_tooltip')"
        >
          <v-switch
            v-model="plugin.ticketwizard_user_info_enabled"
            class="my-0"
          >
            <template slot="label">
              <div class="black--text">
                {{ $t("ticket_template_wizard.enable_user_info_label") }}
              </div>
            </template>
          </v-switch>
        </TranslatableTooltip>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    plugin: {
      get() {
        return this.$store.getters.ticketTemplateWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardPlugin", val);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>
