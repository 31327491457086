<template>
  <v-container class="general">
    <!-- Page title -->
    <page-title :component="'GroupTicketTemplates'">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          :events="activeStep !== 'confirmation'"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <!-- Step header -->
    <AppStepperHeader v-model="stepper" :steps="steps" :loading="isLoading" />
    <!-- Step content -->
    <v-window v-model="stepper" class="mb-5">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component :is="step.component" />
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <div v-if="activeStep !== 'done'">
      <v-btn
        v-if="groupPluginId"
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        :loading="isLoading"
        @click="deletePlugin"
      >
        {{ $t("common.delete") }}
      </v-btn>
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext()"
      >
        {{ nextButtonText }}
      </v-btn>
    </div>
    <ConfirmationDialog ref="deleteConfirmation" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import Ticket from "@/components/TicketTemplateWizard/Ticket.vue";
import Email from "@/components/TicketTemplateWizard/Email.vue";
import Receivers from "@/components/TicketTemplateWizard/Receivers.vue";
import Menu from "@/components/TicketTemplateWizard/Menu.vue";
import EnclosedTicket from "@/components/TicketTemplateWizard/EnclosedTicket.vue";
import CheckinData from "@/components/TicketTemplateWizard/CheckinData.vue";
import Done from "@/components/TicketTemplateWizard/Done.vue";
import moment from "moment-timezone";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
    groupMenu: {},
    groupAudiences: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    plugin: {
      get() {
        return this.$store.getters.ticketTemplateWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardPlugin", val);
      },
    },
    ticket: {
      get() {
        return this.$store.getters.ticketTemplateWizardTicket;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardTicket", val);
      },
    },
    email: {
      get() {
        return this.$store.getters.ticketTemplateWizardEmail;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardEmail", val);
      },
    },
    emailBodyTemplate() {
      return (
        '<p>Dear [property name="first_name" user_id]First name[/property]' +
        "<p>This is your ticket for ___</p>" +
        "<p>If you have downloaded the party app then the ticket will also be visible there. " +
        "When you arrive at the party you need to show your ticket in the app or bring a printed version</p>" +
        "<p>Remember to check the app and the landing page for information, " +
        "it could be that change will occur since the last time you saw it.</p>" +
        "<p>We hope that we will have a great evening together</p>" +
        "<p>Press the download icon below to download the ticket.</p>" +
        "<p>Best wishes</p>" +
        "<p>The party planners</p>" +
        `[ticket ticket_template_id="${this.ticket.id}" user_id]${this.ticket.name}[/ticket]`
      );
    },
    menu: {
      get() {
        return this.$store.getters.ticketTemplateWizardMenu;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardMenu", val);
      },
    },
    steps() {
      return [
        {
          name: "ticket",
          title: this.$t("ticket_template_wizard.ticket_settings"),
          component: Ticket,
          enabled: true,
        },
        {
          name: "email",
          title: this.$t("ticket_template_wizard.email_ticket"),
          component: Email,
          enabled: this.plugin.ticketwizard_email_enabled,
        },
        {
          name: "receivers",
          title: this.$t("ticket_template_wizard.receivers"),
          component: Receivers,
          enabled: this.plugin.ticketwizard_email_enabled,
        },
        {
          name: "enclosed-ticket",
          title: this.$t("ticket_template_wizard.enclosed_ticket_pdf"),
          component: EnclosedTicket,
          enabled: true,
        },
        {
          name: "checkin-data",
          title: this.$t("ticket_template_wizard.checkin_data"),
          component: CheckinData,
          enabled: this.plugin.ticketwizard_user_info_enabled,
        },
        {
          name: "menu",
          title: this.$t("ticket_template_wizard.ticket_in_menu"),
          component: Menu,
          enabled: this.plugin.ticketwizard_menu_enabled,
        },
        {
          name: "done",
          title: this.$t("ticket_template_wizard.done"),
          component: Done,
          enabled: true,
        },
      ].filter((step) => step.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
    nextButtonText() {
      if (this.steps.length - this.stepper !== 2) {
        return this.$t("common.continue");
      }

      return this.$t("common.save");
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  watch: {
    groupPluginId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchAll();
        }
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.fetchAll();
      },
    },
    ticket: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val.id) return;

        if (!this.email.body) {
          this.email.body = this.emailBodyTemplate;
        }
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  created() {
    this.plugin = {};
    this.ticket = {
      body: "",
    };
    this.email = {
      id: null,
      body: "",
      audiences: [],
      recipients: [],
      send_time_start: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    this.menu = {};
  },
  methods: {
    transformDateForSave(date) {
      return date ? moment(date).format("YYYY-MM-DDTHH:mm:ssZ") : null;
    },
    async getGroupPlugin() {
      const params = [
        this.groupId,
        this.groupPluginId,
        { lang: this.appContentLanguage, with_attributes: 1 },
      ];

      const response = await this.$api.groupPlugins.get(...params);

      this.plugin = { ...response.data.data, ...response.data.data.attributes };
    },
    async createGroupPlugin() {
      const specs = [
        this.groupId,
        {
          prefix: "ticketwizard",
          name: this.plugin.name,
          ticketwizard_email_enabled: this.plugin.ticketwizard_email_enabled,
          ticketwizard_menu_enabled: this.plugin.ticketwizard_menu_enabled,
          ticketwizard_user_info_enabled:
            this.plugin.ticketwizard_user_info_enabled,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.create(...specs);

      this.plugin.id = response.data.data.id;
    },
    async updateGroupPlugin() {
      const specs = [
        this.groupId,
        this.plugin.id,
        {
          name: this.plugin.name,
          ticketwizard_email_enabled: this.plugin.ticketwizard_email_enabled,
          ticketwizard_menu_enabled: this.plugin.ticketwizard_menu_enabled,
          ticketwizard_user_info_enabled:
            this.plugin.ticketwizard_user_info_enabled,
          ticketwizard_ticket_information:
            this.plugin.ticketwizard_ticket_information,
          ticketwizard_ticket_fallback_text:
            this.plugin.ticketwizard_ticket_fallback_text,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupPlugins.update(...specs);
    },
    async reloadLeftMenu() {
      const params = [
        this.groupId,
        {
          lang: this.$store.getters.appLanguage,
        },
      ];

      await this.$store.dispatch("getGroupLeftMenu", params);
    },
    async listGroupMenus() {
      const params = [
        this.groupId,
        { lang: this.appContentLanguage, with_disabled: 1 },
      ];

      const response = await this.$api.groupMenus.list(...params);

      const groupMenu = response.data.data.find(
        (el) => el.slug === "group_menu",
      );

      this.groupMenu = groupMenu;

      const menu = response.data.data.find(
        (el) => el.slug === "ionic-app-sidemenu",
      );

      this.menu = menu;

      // find current destination
      const destinationIndex = this.menu.destinations.findIndex(
        (el) =>
          el.component === "TicketPage" &&
          el.group_plugin_id === this.plugin.id,
      );

      if (destinationIndex !== -1) {
        const destination = this.menu.destinations[destinationIndex];

        destination.editable = true;
      } else {
        this.menu.destinations.push({
          icon: "fas question",
          is_public: null,
          editable: true,
        });
      }
    },
    async updateGroupMenu() {
      const destinations = this.menu.destinations.map((el, i) => {
        if (el.editable) {
          return {
            id: el.id,
            name: el.name || this.plugin.name,
            description: el.description,
            component: "TicketPage",
            group_plugin_id: this.plugin.id,
            params: JSON.stringify({
              ticket_template_id: this.ticket.id,
            }),
            is_public: el.is_public,
            icon: el.icon,
            sort_order: i,

            // transform "promoted" before saving
            // TODO: should be done in the in a more central place
            promoted: el.promoted ? 1 : 0,
            enabled: this.plugin.ticketwizard_menu_enabled ? 1 : 0,
          };
        }

        return {
          id: el.id,
          sort_order: i,
        };
      });

      const specs = [
        this.groupId,
        this.menu.id,
        {
          destinations: destinations,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupMenus.update(...specs);
      await this.listGroupMenus();
    },
    async listGroupAudiences() {
      const response = await this.$api.groupAudiences.list(this.groupId, {
        group_plugin_id: this.groupPluginId,
      });

      this.groupAudiences = response.data.data;
    },
    async listGroupTicketTemplates() {
      const specs = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupTicketTemplates.list(...specs);

      if (!response.data.data[0]) return;

      if (response.data.data[0].ticket_header_image) {
        try {
          const mediaResponse = await this.$api.media.get(
            response.data.data[0].ticket_header_image,
          );

          response.data.data[0].ticket_header_image = mediaResponse.data.data;
        } catch (error) {
          if (error) {
            response.data.data[0].ticket_header_image = null;
          }
        }
      }

      this.ticket = response.data.data[0];
    },
    async createGroupTicketTemplate() {
      const specs = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          name: this.plugin.name,
          ticket_header_image: this.ticket.ticket_header_image
            ? this.ticket.ticket_header_image.id
            : null,
          body: this.ticket.body || "<p><br></p>",
          user_info_template: this.ticket.user_info_template,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupTicketTemplates.create(...specs);

      this.ticket.id = response.data.data.id;
    },
    async updateGroupTicketTemplate() {
      const specs = [
        this.groupId,
        this.ticket.id,
        {
          name: this.plugin.name,
          ticket_header_image: this.ticket.ticket_header_image
            ? this.ticket.ticket_header_image.id
            : null,
          body: this.ticket.body,
          user_info_template: this.ticket.user_info_template,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupTicketTemplates.update(...specs);
    },
    async listGroupMessageTemplate() {
      const params = [
        this.groupId,
        { group_plugin_id: this.plugin.id, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupMessageTemplates.list(...params);

      if (!response.data.data[0]) return;

      if (response.data.data[0].message_image) {
        try {
          const mediaResponse = await this.$api.media.get(
            response.data.data[0].message_image,
          );

          response.data.data[0].message_image = mediaResponse.data.data;
        } catch (error) {
          if (error) {
            response.data.data[0].message_image = null;
          }
        }
      }

      this.email = response.data.data[0];
    },
    async createGroupMessageTemplate() {
      const specs = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          name: this.email.name,
          message_image: this.email.message_image
            ? this.email.message_image.id
            : null,
          subject: this.email.subject,
          audiences: this.email.audiences.map((el) => el.id).toString(),
          recipients: this.email.recipients.map((el) => el.id).toString(),
          body: this.email.body,
          type: "email",
          send_time_start: this.email.send_time_start
            ? this.transformDateForSave(this.email.send_time_start)
            : this.transformDateForSave(moment()),
          send_time_end: this.transformDateForSave(this.email.send_time_end),
          active: this.email.active,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupMessageTemplates.create(...specs);

      this.email.id = response.data.data.id;
    },
    async updateGroupMessageTemplate() {
      const specs = [
        this.groupId,
        this.email.id,
        {
          name: this.email.name,
          message_image: this.email.message_image
            ? this.email.message_image.id
            : null,
          subject: this.email.subject,
          audiences: this.email.audiences.map((el) => el.id).toString(),
          recipients: this.email.recipients.map((el) => el.id).toString(),
          body: this.email.body,
          send_time_start: this.email.send_time_start
            ? this.transformDateForSave(this.email.send_time_start)
            : this.transformDateForSave(moment()),
          send_time_end: this.transformDateForSave(this.email.send_time_end),
          lang: this.appContentLanguage,
          active: this.email.active ? 1 : 0,
        },
      ];

      await this.$api.groupMessageTemplates.update(...specs);
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getGroupPlugin();
        await this.listGroupMenus();
        await this.listGroupTicketTemplates();
        await this.listGroupMessageTemplate();
        await this.listGroupAudiences();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
      }
    },
    async createGroupAudiences() {
      const audiences = [
        {
          name: this.$t("ticket_template_wizard.checked_in_audience_name"),
          description: this.$t(
            "ticket_template_wizard.checked_in_audience_description",
          ),
          filters: [
            {
              property: `ticket_${this.ticket.id}`,
              operator: "exists",
              value: 1,
            },
            {
              property: `ticket_${this.ticket.id}.checked_in_at`,
              operator: "exists",
              value: 1,
            },
          ],
          group_plugin_id: this.groupPluginId,
        },
        {
          name: this.$t("ticket_template_wizard.not_checked_in_audience_name"),
          description: this.$t(
            "ticket_template_wizard.not_checked_in_audience_description",
          ),
          filters: [
            {
              property: `ticket_${this.ticket.id}`,
              operator: "exists",
              value: 1,
            },
            {
              property: `ticket_${this.ticket.id}.checked_in_at`,
              operator: "!exists",
              value: 1,
            },
          ],
          group_plugin_id: this.groupPluginId,
        },
        {
          name: this.$t("ticket_template_wizard.without_ticket_audience_name"),
          description: this.$t(
            "ticket_template_wizard.without_ticket_audience_description",
          ),
          filters: [
            {
              property: `ticket_${this.ticket.id}`,
              operator: "!exists",
              value: 1,
            },
          ],
          group_plugin_id: this.groupPluginId,
        },
      ];

      for await (const audience of audiences) {
        const exists = this.groupAudiences.find(
          (ga) => ga.name === audience.name,
        );

        if (!exists) {
          await this.$api.groupAudiences.create(this.groupId, audience);
        }
      }
    },
    async createGroupMenuDestinations() {
      const wizardIndex = this.groupMenu.destinations.findIndex(
        (el) => el.component === "GroupTicketTemplates",
      );

      const tickets = this.groupMenu.destinations.find(
        (el) =>
          el.component === "GroupTickets" &&
          el.group_plugin_id === this.plugin.id,
      );

      if (!tickets) {
        this.groupMenu.destinations.splice(wizardIndex + 1, 0, {
          name: "Tickets",
          icon: "fas ticket-alt",
          params: JSON.stringify({
            ticket_template_id: this.ticket.id,
          }),
          group_plugin_id: this.plugin.id,
          component: "GroupTickets",
          enabled: 1,
        });
      } else {
        tickets.params = JSON.stringify({ ticket_template_id: this.ticket.id });
      }

      const stats = this.groupMenu.destinations.find(
        (el) =>
          el.component === "TicketStats" &&
          el.group_plugin_id === this.plugin.id,
      );

      if (!stats) {
        this.groupMenu.destinations.splice(wizardIndex + 2, 0, {
          name: "Ticket stats",
          icon: "fas clipboard-list",
          params: JSON.stringify({
            ticket_template_id: this.ticket.id,
          }),
          group_plugin_id: this.plugin.id,
          component: "TicketStats",
          enabled: 1,
        });
      } else {
        stats.params = JSON.stringify({ ticket_template_id: this.ticket.id });
      }

      const destinations = this.groupMenu.destinations.map((el, i) => {
        if (el.component === "GroupTickets" || el.component === "TicketStats") {
          el.sort_order = i;

          // transform "promoted" before saving
          // TODO: should be done in the in a more central place
          el.promoted = el.promoted ? 1 : 0;

          return el;
        }

        return {
          id: el.id,
          sort_order: i,
        };
      });

      const specs = [
        this.groupId,
        this.groupMenu.id,
        {
          destinations: destinations,
        },
      ];

      await this.$api.groupMenus.update(...specs);

      this.reloadLeftMenu();
    },
    async deletePlugin() {
      const isConfirmed = await this.$refs.deleteConfirmation.open(
        this.$t("ticket_template_wizard.delete_confirmation"),
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      try {
        this.isLoading = true;

        const params = [this.groupId, this.plugin.id];

        await this.$api.groupPlugins.delete(...params);

        this.$router.push({
          name: "ticket_templates",
        });

        this.isLoading = false;

        this.reloadLeftMenu();
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          this.isLoading = false;
        }
      }
    },
    async changeNext(action) {
      try {
        if (this.activeStep === "ticket") {
          const isValid = await this.$validator.validateAll("ticket");

          if (!isValid) return;

          this.isLoading = true;

          if (!this.plugin.id) {
            await this.createGroupPlugin();

            this.$router.push({
              name: "ticket_templates_edit",
              params: {
                group_plugin_id: this.plugin.id,
              },
            });
          } else {
            await this.updateGroupPlugin();
          }

          if (!this.ticket.id) {
            await this.createGroupTicketTemplate();
          } else {
            await this.updateGroupTicketTemplate();
          }

          if (this.menu.id) {
            await this.updateGroupMenu();
          }

          if (this.email.id && !this.plugin.ticketwizard_email_enabled) {
            this.email.active = 0;
            await this.updateGroupMessageTemplate();
          }
        }

        if (this.activeStep === "email") {
          if (
            !this.email.body.includes(
              `[ticket ticket_template_id="${this.ticket.id}" user_id`,
            )
          ) {
            this.$validator.errors.add({
              field: "body",
              msg: this.$t("ticket_template_wizard.email_body_ticket_error"),
              scope: "email",
            });

            return await this.$validator.validateAll("email");
          }

          const isValid = await this.$validator.validateAll("email");

          if (!isValid) return;

          this.isLoading = true;

          if (!this.email.id) {
            await this.createGroupMessageTemplate();
          } else {
            await this.updateGroupMessageTemplate();
          }
        }

        if (this.activeStep === "receivers") {
          const isValid = await this.$validator.validateAll("receivers");

          if (!isValid) return;

          this.isLoading = true;

          await this.updateGroupMessageTemplate();
        }

        if (this.activeStep === "enclosed-ticket") {
          const isValid = await this.$validator.validateAll("enclosed-ticket");

          if (!isValid) return;

          this.isLoading = true;

          await this.updateGroupTicketTemplate();
          await this.createGroupAudiences();
          await this.createGroupMenuDestinations();
        }

        if (this.activeStep === "checkin-data") {
          const isValid = await this.$validator.validateAll("checkin-data");

          if (!isValid) return;

          this.isLoading = true;

          await this.updateGroupTicketTemplate();
        }

        if (this.activeStep === "menu") {
          const isValid = await this.$validator.validateAll("menu");

          if (!isValid) return;

          this.isLoading = true;

          await this.updateGroupMenu();
          await this.updateGroupPlugin();
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (action === "stay") {
        this.$refs.languageSwitcher.open();
        return;
      }

      this.stepper += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
